import { useDispatch, useSelector } from 'react-redux'
import { updateAndSetTalent, updateAndSetTalentBook, uploadPhotosAndSetTalent, deletePhotosAndSetTalent,
  searchAndSetTalents, deleteAndSetTalent, uploadResumeAndSetTalent, deleteResumeAndSetTalent,
  getTalentByIdSetTalent, setCurrentTalent } from 'store/modules/talent'

const useTalent = () => {
  const talent = useSelector(state => state.talent.currentTalent)
  const talents = useSelector(state => state.talent.talents)
  const dispatch = useDispatch()

  const updateTalent = async (fields) => {
    return await updateAndSetTalent(talent._id, fields, dispatch)
  }

  const updateTalentBook = async (fields) => {
    return await updateAndSetTalentBook(talent._id, fields, dispatch)
  }

  const deleteTalent = async (talentId) => {
    return await deleteAndSetTalent(talentId, dispatch)
  }

  const uploadTalentPhotos = async (formData) => {
    return await uploadPhotosAndSetTalent(talent._id, formData, dispatch)
  }

  const deleteTalentPhotos = async (photos, by_admin) => {
    return await deletePhotosAndSetTalent(talent._id, photos, by_admin, dispatch)
  }

  const uploadTalentResume = async (formData) => {
    return await uploadResumeAndSetTalent(talent._id, formData, dispatch)
  }

  const deleteTalentResume = async (by_admin) => {
    return await deleteResumeAndSetTalent(talent._id, by_admin, dispatch)
  }

  const searchTalents = async (search) => {
    return await searchAndSetTalents(search, dispatch)
  }

  const getTalentAndSet = async (id) => {
    dispatch(setCurrentTalent(null))
    return await getTalentByIdSetTalent(id, dispatch)
  }

  const updateTalentById = async (talendId, fields) => {
    return await updateAndSetTalent(talendId, fields, dispatch)
  }

  return {
    talent,
    talents,
    updateTalent,
    updateTalentBook,
    searchTalents,
    deleteTalent,
    getTalentAndSet,
    uploadTalentPhotos,
    deleteTalentPhotos,
    uploadTalentResume,
    deleteTalentResume,
    updateTalentById
  }
}

export default useTalent
