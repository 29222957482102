import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Text,
  FormErrorMessage,
  Button,
  Modal,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  ModalOverlay,
  ModalContent,
  Link,
  HStack,
  ModalBody
} from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  FaSave,
  FaTrash,
  FaThumbsUp,
  FaThumbsDown,
  FaClock,
  FaMinusCircle,
  FaPlusCircle,
  FaEnvelope,
  FaEye,
  FaFilePdf
} from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Card from "components/Card";
import Select from "components/Select";
import DateInput from "components/DateInput";
import DateComponent from "components/DateComponent";
import useTalent from "hooks/talent";
import { containerWidth } from "styles";
import {
  calcAge,
  formatDate,
  getSearchFilterFromTalent,
  getFileAge,
  adminSearchFilters,
  validateYear
} from "utils";
import useUIContext from "hooks/ui-context";
import { API_URL } from "constants";
import Error from "components/Error";
import TalentPhotos from "views/talent/dashboard-pages/Photos";
import TalentResume from "views/talent/dashboard-pages/Resume";
import TalentSocialFormSection from "components/TalentSocialFormSection";
import TalentContacts from "views/talent/dashboard-pages/Contact";
import moment from 'moment'

import "swiper/css";
import "swiper/css/navigation";

const TalentBookouts = ({ talent, updateTalent, setApiError }) => {
  const removeBookout = async (idx) => {
    let updatedBookouts = talent.bookouts.slice(0)
    updatedBookouts.splice(idx, 1)
    try {
      await updateTalent({ bookouts: updatedBookouts, by_admin: true })
    } catch (err) {
      setApiError(err)
    }
  }

  const filteredBookouts = (talent) => {
    const filteredBookouts = talent?.bookouts?.filter(bookout => !!bookout.start_date);
    return filteredBookouts
  }
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Start Date</Th>
            <Th>End Date</Th>
            <Th>Reason</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredBookouts(talent)?.length === 0 && (
            <Tr>
              <Td colSpan={4} textAlign="center">
                No book-out dates.
              </Td>
            </Tr>
          )}
          {filteredBookouts(talent)
            ?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
            ?.map((bookout, idx) => {
            return (
              <Tr key={idx}>
                <Td>{formatDate(bookout.start_date)}</Td>
                <Td>{formatDate(bookout.end_date)}</Td>
                <Td>{bookout.note}</Td>
                <Td>
                  <FaTrash onClick={() => {
                    removeBookout(idx)
                  }} />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const TalentEdit = () => {
  const { talent, getTalentAndSet, updateTalent, deleteTalent } = useTalent();
  const { id: talentId } = useParams("id");
  const {
    control,
    handleSubmit,
    register,
    setValue,
    setError,
    formState: { errors },
    reset,
  } = useForm();
  const [apiError, setApiError] = useState("");
  const [talentStatus, setTalentStatus] = useState("");
  const [loadingFlag, setLoadingFlag] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const { setBreadcrumbs, addNotification, showConfirmModal } = useUIContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [contract_exp_date, setContract_exp_date] = useState(null);
  const [work_permit_exp, setWork_permit_exp] = useState(null);
  const [showInput, setShowInput] = useState(false)


  const handlePhotoClick = (index) => {
    setSelectedPhotoIndex(index);
    setIsPhotoModalOpen(true);
  };

  const handlePhotoCloseModal = () => {
    setSelectedPhotoIndex(null);
    setIsPhotoModalOpen(false);
  };

  useEffect(() => {
    getTalentAndSet(talentId);
  }, [talentId]);

  useEffect(() => {
    if (talent) {
      setContract_exp_date(talent?.contract_exp_date)
      setWork_permit_exp(talent?.work_permit_exp)

      reset({
        accepted_date: "",
        ...talent,
        username: talent.user ? talent.user.username : "",
        admin_note: talent.admin_note ? talent.admin_note : "",
      });

      try {
        setTalentStatus(checkTalentStatus(talent));
      } catch (err) {
        console.log("err: ", err);
        setTalentStatus("");
      }
    }
    setBreadcrumbs([
      {
        path: "/admin/dashboard",
        label: "Dashboard",
      },
      {
        path: "/admin/dashboard/talents",
        label: "Talent",
      },
      {
        path: `/admin/dashboard/talent/edit/${talent && talent._id}`,
        label: talent
          ? `${talent.first_name} ${talent.last_name} (${talentStatus})`
          : "Talent",
      },
    ]);
  }, [talent, talentStatus]);

  const checkTalentStatus = (talent) => {
    if(talent.is_accepted && talent.is_active && talent.is_live && !talent.is_long_term_pending) {
      setSearchParams({ status: "accepted" })
      return adminSearchFilters["accepted"].label
    }
    if(talent.is_accepted && !talent.is_active) {
      setSearchParams({ status: "inactive" })
      return adminSearchFilters["inactive"].label
    }
    if(talent.is_long_term_pending) {
      setSearchParams({ status: "long_term_pending" })
      return adminSearchFilters["long_term_pending"].label
    }
    if(talent.accepted_date === null && !talent.is_long_term_pending && talent.is_accepted === false) {
      setSearchParams({ status: "declined" })
      return adminSearchFilters["declined"].label
    }
    if(!!talent.accepted_date && (talent.is_accepted !== false && talent.is_accepted !== true) && !talent.is_talent_postpone) {
      setSearchParams({ status: "pending_decision" })
      return adminSearchFilters["pending_decision"].label
    }
    if(!!talent.accepted_date && talent.is_accepted === false) {
      setSearchParams({ status: "decline_from_talent" })
      return adminSearchFilters["decline_from_talent"].label
    }
    if(!!talent.accepted_date && talent.is_talent_postpone) {
      setSearchParams({ status: "talent_postponed_count" })
      return adminSearchFilters["talent_postponed_count"].label
    }
    if(talent.soft_deleted) {
      setSearchParams({ status: "recently_deleted" })
      return adminSearchFilters["recently_deleted"].label
    }
    setSearchParams({ status: "new" })
    return adminSearchFilters["new"].label
  }

  const enumData = useSelector((state) => state.talent.enumData);

  const handleSave = async (values) => {
    setSaving(true);
    setApiError("");
    try {
      // const sendData = {...values, is_over_25: values.is_over_25 ? values.is_over_25 : false};
      const sendData = {...values, contract_exp_date, work_permit_exp, by_admin: true}
      const res = await updateTalent(sendData);
      if (res.success) {
        setShowInput(false)
        addNotification({
          status: "success",
          content: "Saved",
        });
      } else {
        setError(
          "username",
          { type: "focus", message: "Username already exists" },
          { shouldFocus: true }
        );
      }
    } catch (err) {
      setApiError(`${err}`);
    }
    setSaving(false);
  };

  const deleteConfirmHandle = async (action) => {
    if (action !== "yes") {
      return;
    }
    await deleteTalent(talent._id);
    addNotification({
      status: "error",
      content: "Deleted",
    });
    navigate("/admin/dashboard/talents");
  };

  const updateFlagValue = async (fields) => {
    const flags = Object.keys(fields).reduce(
      (flags, k) => ({ ...flags, [k]: true }),
      {}
    );
    console.log('fields: ', fields);
    setLoadingFlag({ ...flags });
    setApiError("");
    try {
      await updateTalent({
        ...fields,
        by_admin: true
      });
      addNotification({
        status: "success",
        content: "Updated",
      });
    } catch (err) {
      setApiError(err);
    }
    setLoadingFlag({});
  };

  if (!enumData || !talent) {
    return;
  }

  return (
    <Box width={containerWidth} mx="auto" mb={12}>
      <Card
        title={`${talent.first_name} ${
          talent.last_name
        } (${talentStatus}) ${calcAge(talent)}`}
      >
        <Box display="flex">
          <Button onClick={onOpen}>Edit Photos</Button>
          <Button onClick={() => setIsResumeModalOpen(true)} ml={2}>Upload New Resume</Button>
          <Button
            ml="auto"
            leftIcon={<FaEye />}
            onClick={() => {
              window.open(`/talent/${talent._id}`);
            }}
          >
            View Full Profile
          </Button>
          {talent?.resume && (
            <Button leftIcon={<FaFilePdf />}  ml={2} onClick={() => {
              window.open(`${API_URL}/s3/${encodeURIComponent(talent?.resume)}`, '_blank')
            }}>
              Download Resume
            </Button>
          )}
          <Button
            ml={2}
            as={Link}
            leftIcon={<FaEnvelope />}
            href={`mailto:${talent.email}`}
            target="_blank"
          >
            Email
          </Button>
        </Box>
        <Box my={6}>
          <Swiper 
            breakpoints={{
              // when window width is >= 768px
                  768: {
                    slidesPerView: 2,
                  },
              // when window width is >= 1024px
                  1280: {
                    slidesPerView: 3,
                  },
                }}
            slidesPerView={1} 
            navigation={true}
            modules={[Navigation]}>
            {talent?.photos?.map((photo, index) => {
              return (
                <SwiperSlide key={photo}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    onClick={() => handlePhotoClick(index)}
                  >
                    <Image
                      height={300}
                      borderRadius={5}
                      objectFit="contain"
                      overflow="hidden"
                      src={`${API_URL}/s3/${encodeURIComponent(
                        "300x300/" + photo
                      )}`}
                      px={3}
                      cursor="pointer"
                    />
                    <Text mt={3} whiteSpace="nowrap">
                      {getFileAge(photo)}
                    </Text>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
        <form onSubmit={handleSubmit(handleSave)}>
          <Box
            display={{base:"flex",lg:"grid"}}
            gridTemplateColumns={{
              base: "1fr",
              lg: "1fr 1fr",
              xl: "repeat(4, 1fr)",
            }}
            mt={3}
            gridGap={3}
            flexDirection={{base:"column"}}
          >
            <FormControl isInvalid={errors.first_name}>
              <FormLabel>First Name *</FormLabel>
              <Input
                {...register("first_name", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.first_name && errors.first_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.last_name}>
              <FormLabel>Last Name *</FormLabel>
              <Input
                {...register("last_name", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.last_name && errors.last_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.gender}>
              <FormLabel>Gender *</FormLabel>
              <Select
                control={control}
                isMulti
                name="gender"
                rules={{ required: "This field is required" }}
                options={enumData.gender}
              />
              <FormErrorMessage>
                {errors.gender && errors.gender.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.eye_color}>
              <FormLabel>Eye Color *</FormLabel>
              <Select
                control={control}
                name="eye_color"
                rules={{ required: "This field is required" }}
                options={enumData.eye_color}
              />
              <FormErrorMessage>
                {errors.eye_color && errors.eye_color.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.hair_color}>
              <FormLabel>Hair Color *</FormLabel>
              <Select
                control={control}
                name="hair_color"
                rules={{ required: "This field is required" }}
                options={enumData.hair_color}
              />
              <FormErrorMessage>
                {errors.hair_color && errors.hair_color.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.cloth_size}>
              <FormLabel>Clothing Size *</FormLabel>
              <Select
                control={control}
                name="cloth_size"
                rules={{ required: "This field is required" }}
                options={enumData.cloth_size}
              />
              <FormErrorMessage>
                {errors.cloth_size && errors.cloth_size.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.shoe_size}>
              <FormLabel>Shoe Size *</FormLabel>
              <Select
                control={control}
                name="shoe_size"
                rules={{ required: "This field is required" }}
                options={enumData.shoe_size}
              />
              <FormErrorMessage>
                {errors.shoe_size && errors.shoe_size.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.height}>
              <FormLabel>Height *</FormLabel>
              <Select
                control={control}
                name="height"
                rules={{ required: "This field is required" }}
                options={enumData.height}
              />
              <FormErrorMessage>
                {errors.height && errors.height.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.weight}>
              <FormLabel>Weight *</FormLabel>
              <Select
                control={control}
                name="weight"
                rules={{ required: "This field is required" }}
                options={enumData.weight}
              />
              <FormErrorMessage>
                {errors.weight && errors.weight.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.ethnicity_actual}>
              <FormLabel>Ethnic Heritage (actual) *</FormLabel>
              <Select
                control={control}
                isMulti
                name="ethnicity_actual"
                rules={{ required: "This field is required" }}
                options={enumData.ethnicity}
              />
              <FormErrorMessage>
                {errors.ethnicity_actual && errors.ethnicity_actual.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.ethnicity_look}>
              <FormLabel>Racial Appearance *</FormLabel>
              <Select
                control={control}
                isMulti
                name="ethnicity_look"
                rules={{ required: "This field is required" }}
                options={enumData.racial_appearance}
              />
              <FormErrorMessage>
                {errors.ethnicity_look && errors.ethnicity_look.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl gridColumn="1 / -1">
              <FormLabel>Actual Ethnicity Specifics</FormLabel>
              <Textarea
                minHeight="unset"
                rows={3}
                {...register("ethnicity_note")}
              />
            </FormControl>

            <FormControl isInvalid={errors.body_type}>
              <FormLabel>Body Type</FormLabel>
              <Select
                control={control}
                isMulti
                name="body_type"
                options={enumData.body_type}
              />
              <FormErrorMessage>
                {errors.body_type && errors.body_type.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>Special Skills</FormLabel>
              <Select
                control={control}
                isMulti
                name="special_skills"
                options={enumData.skills}
              />
            </FormControl>

            <FormControl isInvalid={errors.is_homeschooled}>
              <FormLabel>Is Homeschooled</FormLabel>
              <Select
                control={control}
                name="is_homeschooled"
                options={enumData.boolean_value}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Other abled?</FormLabel>
              <Select
                control={control}
                name="other_abled"
                options={enumData.boolean_value}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Stage First Name</FormLabel>
              <Input {...register("stage_first_name")} />
            </FormControl>
            <FormControl>
              <FormLabel>Stage Last Name</FormLabel>
              <Input {...register("stage_last_name")} />
            </FormControl>
            <FormControl isInvalid={errors.email}>
              <FormLabel>Email *</FormLabel>
              <Input
                // readOnly={talent.accepted_date}
                type="email"
                {...register("email", { required: "This field is required" })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            {talent.user && (
              <FormControl isInvalid={errors.username}>
                <FormLabel>Username *</FormLabel>
                <Input
                  {...register("username", {
                    required: "This field is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.username && errors.username.message}
                </FormErrorMessage>
              </FormControl>
            )}
            <FormControl isInvalid={errors.birth_date}>
                {typeof talent.is_over_25 !== "boolean" ? (<FormLabel>Date of Birth *</FormLabel>) : (<FormLabel>Date of Birth </FormLabel>)}
                <DateInput
                  control={control}
                  name="birth_date"
                  rules={{ 
                    required: typeof talent.is_over_25 !== "boolean" ? "This field is required" : false,
                    validate: validateYear
                  }}
                />
                <FormErrorMessage>
                  {errors.birth_date && errors.birth_date.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.is_over_25}>
              <FormLabel>Is Over 25 ?</FormLabel>
              <Select
                control={control}
                isClearable={true}
                name="is_over_25"
                options={enumData.boolean_value}
                // rules={{ required: "You must select either over or under 25. We ask this to know if you are eligible to participate in alcohol advertising" }}
              />
              <FormErrorMessage>
                {errors.is_over_25 && 
                "You must select either over or under 25. We ask this to know if you are eligible to participate in alcohol advertising"}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.is_twin}>
              <FormLabel>Is Twin?</FormLabel>
              <Select
                control={control}
                name="is_twin"
                options={enumData.boolean_value}
              />
              <FormErrorMessage>
                {errors.is_twin && errors.is_twin.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Submitted</FormLabel>
              <DateInput control={control} name="submitted_date" />
            </FormControl>
            <FormControl>
              <FormLabel>Accepted</FormLabel>
              {talent.accepted_date ? 
                <Box px={3} py={2} h="40px" border='1px' borderColor='gray.200' borderRadius={5}>
                  {moment(talent.accepted_date).format('YYYY-MM-DD')} 
                </Box>
                : <Box px={3} py={2} h="40px" border='1px' borderColor='gray.200' borderRadius={5}>
                    No accepted date
                  </Box>
              }
            </FormControl>
            <FormControl>
              <FormLabel>Profile Last Update</FormLabel>
              <DateInput control={control} name="last_update" />
            </FormControl>

            <DateComponent 
              setContract_exp_date={setContract_exp_date}
              contract_exp_date={contract_exp_date}
              setWork_permit_exp={setWork_permit_exp}
              work_permit_exp={work_permit_exp}
              showInput={showInput}
              setShowInput={setShowInput} />
              
            <FormControl gridColumn="1 / -1">
              <FormLabel>Admin Notes</FormLabel>
              <Textarea {...register("admin_note")} />
            </FormControl>
            <Box
              gridColumn="1 / -1"
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <FormControl display="flex" w="auto">
                <Checkbox {...register("flags.lac")} />
                <FormLabel mb={0} ml={2} mr={0}>
                  LAC
                </FormLabel>
              </FormControl>
              <FormControl display="flex" w="auto">
                <Checkbox {...register("flags.aa")} />
                <FormLabel mb={0} ml={2} mr={0}>
                  AA
                </FormLabel>
              </FormControl>
              <FormControl display="flex" w="auto">
                <Checkbox {...register("flags.cf")} />
                <FormLabel mb={0} ml={2} mr={0}>
                  CF
                </FormLabel>
              </FormControl>
              <FormControl display="flex" w="auto">
                <Checkbox {...register("flags.w9")} />
                <FormLabel mb={0} ml={2} mr={0}>
                  W9
                </FormLabel>
              </FormControl>
              <FormControl display="flex" w="auto">
                <Checkbox {...register("flags.ca")} />
                <FormLabel mb={0} ml={2} mr={0}>
                  CA
                </FormLabel>
              </FormControl>
              <FormControl display="flex" w="auto">
                <Checkbox {...register("flags.wp")} />
                <FormLabel mb={0} ml={2} mr={0}>
                  WP
                </FormLabel>
              </FormControl>
              <FormControl display="flex" w="auto">
                <Checkbox {...register("flags.nd")} />
                <FormLabel mb={0} ml={2} mr={0}>
                  ND
                </FormLabel>
              </FormControl>
            </Box>
            <Box gridColumn="1 / -1">
              <Text mb={1}>Available For</Text>
              <Box display="flex">
                <FormControl display="flex" w="auto" mr={5}>
                  <Checkbox {...register("available_for.t")} />
                  <FormLabel mb={0} ml={2} mr={0}>
                    Theatrical
                  </FormLabel>
                </FormControl>
                <FormControl display="flex" w="auto" mr={5}>
                  <Checkbox {...register("available_for.p")} />
                  <FormLabel mb={0} ml={2} mr={0}>
                    Print
                  </FormLabel>
                </FormControl>
                <FormControl display="flex" w="auto">
                  <Checkbox {...register("available_for.c")} />
                  <FormLabel mb={0} ml={2} mr={0}>
                    Commercial
                  </FormLabel>
                </FormControl>
              </Box>
            </Box>
            <Box gridColumn="1 / -1">
              <Text>Bookouts</Text>
              <TalentBookouts talent={talent} updateTalent={updateTalent} setApiError={setApiError} />
            </Box>
            <Box gridColumn="1 / -1">
              <Text>Contacts</Text>
              <TalentContacts asComponent={true} />
            </Box>

            <FormControl>
              <FormLabel>Casting Frontier Barcode</FormLabel>
              <Input {...register("casting_frontier_barcode")} />
            </FormControl>
            <FormControl>
              <FormLabel>Union Status</FormLabel>
              <Select
                control={control}
                name="union_status"
                options={enumData.union}
              />
            </FormControl>
            <FormControl>
              <FormLabel>SAG-AFTRA ID (if applicable)</FormLabel>
              <Input {...register("sag_aftra_id")} />
            </FormControl>

            {/* <FormControl gridColumnStart='1'>
              <FormLabel>Street</FormLabel>
              <Input {...register('address.street')} />
            </FormControl> */}
            <FormControl
              gridColumnStart="1"
              isInvalid={errors.address && errors.address.city}
            >
              <FormLabel>City *</FormLabel>
              <Input
                {...register("address.city", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.address &&
                  errors.address.city &&
                  errors.address.city.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.address && errors.address.state}>
              <FormLabel>State *</FormLabel>
              <Input
                {...register("address.state", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.address &&
                  errors.address.state &&
                  errors.address.state.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.address && errors.address.zip}>
              <FormLabel>Zip *</FormLabel>
              <Input
                {...register("address.zip", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.address &&
                  errors.address.zip &&
                  errors.address.zip.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              gridColumnStart="1"
              isInvalid={errors.phones && errors.phones[0]}
            >
              <FormLabel>Phone (1) *</FormLabel>
              <Input
                {...register("phones[0]", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.phones && errors.phones[0] && errors.phones[0].message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Phone (2)</FormLabel>
              <Input {...register("phones[1]")} />
            </FormControl>

            <FormControl gridColumnStart="1">
              <FormLabel>Also represented by</FormLabel>
              <Input {...register("represented_by")} />
            </FormControl>

            <FormControl gridColumn="span 2">
              <FormLabel>Message</FormLabel>
              <Textarea {...register("message")} />
            </FormControl>

            <FormControl>
              <FormLabel>Additional Searches</FormLabel>
              <Select
                control={control}
                isMulti
                name="additional_searches"
                options={enumData.additional_searches}
              />
            </FormControl>

            <TalentSocialFormSection
              prefix=""
              register={register}
              setValue={setValue}
              boxProps={{
                gridColumn: "1 / -1",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 3,
              }}
            />

            <Error error={apiError} />
            <Box gridColumn="1 / -1" mt={4} display="flex" flexWrap="wrap">
              <Text w="full" ml={3} mb={2} fontSize="sm">
                {talent.is_talent_postpone && "Talent postponed his answer."}
              </Text>
              <Button
                mr={2}
                type="submit"
                leftIcon={<FaSave />}
                disabled={saving}
                isLoading={saving}
              >
                Save
              </Button>
              <Button
                mr="auto"
                colorScheme="red"
                variant="outline"
                leftIcon={<FaTrash />}
                onClick={() => {
                  showConfirmModal({
                    title: "Are you sure?",
                    content: "You won't be able to revert this action!",
                    buttons: [
                      {
                        label: "Yes",
                        action: "yes",
                        color: "red",
                      },
                      {
                        label: "Cancel",
                        action: "cancel",
                      },
                    ],
                    callback: deleteConfirmHandle,
                  });
                }}
              >
                Delete
              </Button>
              <Button
                colorScheme="green"
                variant={talent.is_accepted === true ? "solid" : "outline"}
                disabled={loadingFlag["is_accepted"]}
                leftIcon={<FaThumbsUp />}
                ml={2}
                onClick={() => {
                  !talent.is_accepted &&
                    updateFlagValue({
                      // ...!talent.accepted_date && {is_accepted: true},
                      // is_talent_postpone: false,
                      accepted_date: new Date(),
                      resend_email: true,
                      is_accepted: null,
                      is_long_term_pending: false
                    });
                }}
                mb={2}
              >
                {talent.is_accepted
                  ? "Accepted"
                  : !talent.accepted_date
                  ? "Accept"
                  : "Resend Accept Email"}
              </Button>
              {/* {!talent.is_accepted && ( */}
              {(
                <Button
                  colorScheme="red"
                  variant={talent.is_accepted === false ? "solid" : "outline"}
                  disabled={loadingFlag["is_accepted"]}
                  leftIcon={<FaThumbsDown />}
                  ml={2}
                  onClick={() => {
                    updateFlagValue({
                      is_accepted: false,
                      accepted_date: null,
                      is_long_term_pending: false
                    });
                  }}
                  mb={2}
                >
                  {talent.is_accepted === false
                    ? talent.accepted_date
                      ? "Declined by talent"
                      : "Declined"
                    : "Decline"}
                </Button>
              )}
              <Button
                leftIcon={<FaClock />}
                ml={2}
                variant={
                  talent.is_long_term_pending === false ? "solid" : "outline"
                }
                backgroundColor={
                  talent.is_long_term_pending === false ? "" : '#8d8f91'
                }
                isDisabled={talent.is_long_term_pending === true || loadingFlag["is_long_term_pending"]}
                onClick={() => {
                  updateFlagValue({
                    is_long_term_pending: !talent.is_long_term_pending,
                    accepted_date: null,
                    is_accepted: null
                  });
                }}
                mb={2}
              >
                {talent.is_long_term_pending
                  ? "Long Term Pending"
                  : "Long Term Pending"}
              </Button>
              <Button
                ml={2}
                variant={talent.is_active ? "outline" : "solid"}
                colorScheme={talent.is_active ? "red" : "green"}
                leftIcon={
                  talent.is_active ? <FaMinusCircle /> : <FaPlusCircle />
                }
                disabled={loadingFlag["is_active"]}
                onClick={() => {
                  updateFlagValue({ is_active: !talent.is_active });
                }}
                mb={2}
              >
                Make {talent.is_active ? "Inactive" : "Active"}
              </Button>
              <Button
                ml={2}
                variant={talent.is_live ? "outline" : "solid"}
                colorScheme={talent.is_live ? "red" : "green"}
                leftIcon={talent.is_live ? <FaMinusCircle /> : <FaPlusCircle />}
                disabled={loadingFlag["is_live"]}
                onClick={() => {
                  updateFlagValue({ is_live: !talent.is_live });
                }}
                mb={2}
              >
                Make {talent.is_live ? "Not Live" : "Live"}
              </Button>
            </Box>
          </Box>
        </form>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" position="sticky" top={0}>
            Edit Photos
            <Button
              colorScheme="red"
              ml="auto"
              onClick={onClose}
              variant="outline"
            >
              Close
            </Button>
          </ModalHeader>
          <TalentPhotos by_admin={true} />
        </ModalContent>
      </Modal>
      <Modal isOpen={isResumeModalOpen} onClose={() => setIsResumeModalOpen(!isResumeModalOpen)} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" position="sticky" top={0}>
            Upload Resume
            <Button
              colorScheme="red"
              ml="auto"
              onClick={() => setIsResumeModalOpen(!isResumeModalOpen)}
              variant="outline"
            >
              Close
            </Button>
          </ModalHeader>
          <TalentResume by_admin={true} />
        </ModalContent>
      </Modal>
      <Modal isOpen={isPhotoModalOpen} onClose={handlePhotoCloseModal} size="">
        <ModalOverlay />
        <ModalContent maxWidth={{base:"75vw",sm: "60vw", md:"40vw"}}>
          <ModalCloseButton />
          <ModalBody>
            <Image
              src={`${API_URL}/s3/${encodeURIComponent(talent.photos[selectedPhotoIndex])}`}
              maxHeight="90vh"
              mx="auto"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TalentEdit;
