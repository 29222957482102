import { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { setEnumData } from 'store/modules/talent'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { getEnumValue, getLoggedInUser } from 'services'

import UserLayout from 'layout/UserLayout'
import Login from 'views/auth/Login'
import ResetPasswordRequest from './views/auth/ForgetPassword'
import ResetPassword from './views/auth/ResetPassword'


import SubmitForm from 'views/talent/Submit'
import TalentDashboard from 'views/talent/Dashboard'
import TalentPhotos from 'views/talent/dashboard-pages/Photos'
import TalentDetails from 'views/talent/dashboard-pages/Details'
import TalentBookOutDates from 'views/talent/dashboard-pages/BookOut'
import TalentAddress from 'views/talent/dashboard-pages/Address'
import TalentContacts from 'views/talent/dashboard-pages/Contact'
import ResumeUpload from 'views/talent/dashboard-pages/Resume'
import AdminEnumEdit from 'views/admin/pages/EnumEdit'

import TalentView from 'views/talent/TalentView'

import AdminDashboard from 'views/admin/Dashboard'
import AdminTalentList from 'views/admin/pages/TalentList'
import AdminTalentEdit from 'views/admin/pages/TalentEdit'
import AdminClientList from 'views/admin/pages/ClientList'
import AdminTalentAffiliation from 'views/admin/pages/Affiliation'

import ClientDashboard from 'views/client/Dashboard'
import TalentChoice from 'views/auth/TalentChoice'

import SearchTalents from 'views/client/pages/SearchTalents'
import SearchList from 'views/client/pages/Searches'

import ProducerSearchTalents from 'views/producer/pages/ProducerSearchTalents'

import UserProfile from 'views/auth/Profile'

import { setCurrentUser, setToken } from 'store/modules/user'
import { setRootLoading } from 'store/modules/app'
import { USER_TYPES } from 'constants'
import './App.css'
import { useColorMode } from '@chakra-ui/react'
import AuthWrapper from 'components/AuthWrapper'
import AdminsList from 'views/admin/pages/AdminsList'
import { SUPER_ADMIN_USERNAMES } from 'constants'

const nonAuthRoutes = ['/auth', '/talent/submission', '/talent-choice', '/client/dashboard/search', '/talent/']

const App = () => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const navigate = useNavigate()
  const location = useLocation()
  const enumData = useSelector(state => state.talent.enumData)
  const user = useSelector(state => state.user.currentUser)
  const { colorMode, toggleColorMode } = useColorMode()

  useEffect(() => {
    const initData = async () => {
      // if (colorMode === 'light') {
        // toggleColorMode()
      // }
      dispatch(setRootLoading(true))
      const enumRes = await getEnumValue()
      dispatch(setEnumData(enumRes.data))
      const requireLogin = !nonAuthRoutes.find(item => location.pathname.startsWith(item))
      if (!requireLogin) {
        dispatch(setRootLoading(false))
        return
      }
      if (localStorage.getItem('token')) {
        try {
          const userRes = await getLoggedInUser()
          if (userRes.data) {
            dispatch(setCurrentUser(userRes.data))
            if (userRes.data.user_type === USER_TYPES.TALENT && !location.pathname.startsWith('/talent/dashboard')) {
              navigate('/talent/dashboard')
            }
            if (userRes.data.user_type === USER_TYPES.ADMIN && location.pathname === '/') {
              navigate('/admin/dashboard')
            }
            if (userRes.data.user_type === USER_TYPES.CLIENT && location.pathname === '/') {
              navigate('/client/dashboard')
            }
          } else {
            if (!window.location.pathname.startsWith('/talent-choice')) {
              dispatch(setToken(null))
              if (requireLogin) {
                navigate('/auth/login')
              }
            }
          }
        } catch (err) {
          dispatch(setToken(null))
          if (requireLogin) {
            navigate('/auth/login')
          }
        }
      } else {
        if (requireLogin) {
          navigate('/auth/login')
        }
      }
      dispatch(setRootLoading(false))
    }
    initData()
  }, [token])

  if (!enumData) { return null }

  return (
    <Routes>
      <Route path='auth'>
        <Route path='login' element={<Login />} />
        <Route path="reset-password-request" element={<ResetPasswordRequest />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>

      <Route path='talent'>
        <Route path='submission' element={<SubmitForm />} />
        <Route path=':talentId' element={<TalentView />} />
        {user && (
          <Route path='dashboard' element={<AuthWrapper><UserLayout /></AuthWrapper>}>
            <Route index element={<AuthWrapper><TalentDashboard /></AuthWrapper>} />
            <Route path='photos' element={<AuthWrapper><TalentPhotos by_admin={false} /></AuthWrapper>} />
            <Route path='details' element={<AuthWrapper><TalentDetails /></AuthWrapper>} />
            <Route path='bookout' element={<AuthWrapper><TalentBookOutDates /></AuthWrapper>} />
            <Route path='address' element={<AuthWrapper><TalentAddress /></AuthWrapper>} />
            <Route path='contacts' element={<AuthWrapper><TalentContacts /></AuthWrapper>} />
            <Route path='resume' element={<AuthWrapper><ResumeUpload by_admin={false} /></AuthWrapper>} />
          </Route>
        )}
      </Route>

      {user && (
        user.user_type === USER_TYPES.ADMIN ? (
          <Route path='admin'>
            <Route path='dashboard' element={<AuthWrapper><UserLayout /></AuthWrapper>}>
              <Route index element={<AuthWrapper><AdminDashboard /></AuthWrapper>} />
              <Route path='talents' element={<AuthWrapper><AdminTalentList /></AuthWrapper>} />
              <Route path='talent/edit/:id' element={<AuthWrapper><AdminTalentEdit /></AuthWrapper>} />
              <Route path='talents-affiliation' element={<AuthWrapper><AdminTalentAffiliation /></AuthWrapper>} />
              <Route path='clients' element={<AuthWrapper><AdminClientList /></AuthWrapper>} />
              <Route path='search/:id' element={<AuthWrapper><SearchTalents /></AuthWrapper>} />
              <Route path='searches' element={<AuthWrapper><SearchList /></AuthWrapper>} />
            </Route>
          </Route>
        ) : (
          <Route path='admin'>
              <Route path='*' element={<Navigate to={`/${user.user_type}/dashboard`} />} />
          </Route>
        )
      )}
      {user && (
        user.user_type === USER_TYPES.ADMIN ? (
          <Route path='enum-edit' element={<AuthWrapper><UserLayout /></AuthWrapper>}>
            <Route index element={<AuthWrapper><AdminEnumEdit /></AuthWrapper>} />
          </Route>
        ) : (
          <Route path='enum-edit'>
              <Route path='*' element={<Navigate to={`/${user.user_type}/dashboard`} />} />
          </Route>
        )
      )}
      {user && (
        user.user_type === USER_TYPES.ADMIN && SUPER_ADMIN_USERNAMES.includes(user?.username) ? (
          <Route path='admin-edit' element={<AuthWrapper><UserLayout /></AuthWrapper>}>
            <Route index element={<AuthWrapper><AdminsList /></AuthWrapper>} />
          </Route>
        ) : (
          <Route path='admin-edit'>
              <Route path='*' element={<Navigate to={`/${user.user_type}/dashboard`} />} />
          </Route>
        )
      )}
      {user && (
        (user.user_type === USER_TYPES.CLIENT) ? (
          <Route path='client'>
            <Route path='dashboard' element={<AuthWrapper><UserLayout /></AuthWrapper>}>
              <Route index element={<AuthWrapper><ClientDashboard /></AuthWrapper>}/>
              <Route path='search/:id' element={<AuthWrapper><SearchTalents /></AuthWrapper>} />
              <Route path='searches' element={<AuthWrapper><SearchList /></AuthWrapper>} />
            </Route>

          </Route>
        ) : (
          <Route path='client'>
              <Route path='*' element={<Navigate to={`/${user.user_type}/dashboard`} />} />
          </Route>
        )
      )}

      {!user && <Route path='client' element={<UserLayout />}>
        <Route path='dashboard/search/:id' element={<ProducerSearchTalents />} />
        <Route path='*' element={<Navigate to="/client/dashboard" />} />
      </Route>}

      {user && (
        <Route path='profile' element={<AuthWrapper><UserLayout /></AuthWrapper>}>
          <Route index element={<AuthWrapper><UserProfile /></AuthWrapper>} />
        </Route>
      )}

      <Route path='talent-choice' element={<TalentChoice />} />
    </Routes>
  )
}
    

export default App
