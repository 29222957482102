import axiosInstance from './service'

const getEnumValue = async () => {
  const res = await axiosInstance.get('/talent/enums')
  return res
}

const talentOnboard = async (talent, contact) => {
  const res = await axiosInstance.post('/talent/', {
    talent, contact
  })
  return res
}

const getTalentById = async (id) => {
  const res = await axiosInstance.get(`/talent/by-id/${id}`)
  return res
}

const getTalentByUser = async (userId) => {
  const res = await axiosInstance.get(`/talent/by-user/${userId}`)
  return res
}

const updateTalent = async (talentId, talent) => {
  const res = await axiosInstance.put(`/talent/${talentId}`, {
    talent
  })
  return res
}

const updateTalentBook = async (talentId, talent) => {
  const res = await axiosInstance.put(`/talent/${talentId}?isBook=true`, {
    talent
  })
  return res
}

const uploadTalentPhotos = async (talentId, formData) => {
  const res = await axiosInstance.post(`/talent/${talentId}/photos`, formData)
  return res
}

const uploadNewTalentPhotos = async (formData) => {
  const res = await axiosInstance.post(`/talent/new-talent-photos`, formData)
  return res
}

const deleteTalentPhotos = async (talentId, photos, by_admin) => {
  const res = await axiosInstance.delete(`/talent/${talentId}/photos`, {
    data: { keys: photos, by_admin: by_admin }
  })
  return res
}

const uploadTalentResume = async (talentId, formData) => {
  const res = await axiosInstance.post(`/talent/${talentId}/resume`, formData)
  return res
}

const deleteTalentResume = async (talentId, by_admin) => {
  const res = await axiosInstance.delete(`/talent/${talentId}/resume`, {
    data: { by_admin: by_admin }
  })
  return res
}

const searchTalents = async (search) => {
  const res = await axiosInstance.post(`/talent/search-talents`, search)
  return res
}

const deleteTalent = async (talentId) => {
  const res = await axiosInstance.delete(`/talent/${talentId}`)
  return res
}

const updateEnumValues = async (values) => {
  const res = await axiosInstance.post('/talent/enums', { enums: values })
  return res
}

const talentResponseUpdate = async (choice) => {
  const res = await axiosInstance.post('/talent/talent-choice', { choice })
  return res
}

const exportCsv = async (query) => {
  const res = await axiosInstance.post('/talent/export-csv', { query })
  return res
}

export {
  getEnumValue,
  talentOnboard,
  updateTalent,
  updateTalentBook,
  uploadTalentPhotos,
  uploadNewTalentPhotos,
  getTalentByUser,
  deleteTalentPhotos,
  uploadTalentResume,
  deleteTalentResume,
  deleteTalent,
  searchTalents,
  getTalentById,
  
  updateEnumValues,
  talentResponseUpdate,
  exportCsv
}
