import { useEffect, useMemo, useState } from 'react'
import { Image, Box, Text, Button, Collapse, Spinner, FormControl,
  FormLabel, Input, FormErrorMessage, Modal, ModalOverlay, Link,
  ModalContent, Checkbox, List, ListItem, Flex } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { containerWidth } from 'styles'
import useSearch from 'hooks/search'
import ClientSearch from 'components/ClientSearch'
import { setCurrentSearch, clearCurrentSearch } from 'store/modules/search'
import { listTalentsBySearch } from 'services'
import { API_URL } from 'constants'
import { colors } from 'styles'
import { FaCaretDown, FaCaretUp, FaCopy, FaEnvelope, FaEye, FaFilePdf,
  FaSortAlphaDown, FaSortAlphaUp
 } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import ClientSelect from 'components/ClientSelect'
import useUIContext from 'hooks/ui-context'
import Card from 'components/Card'
import { calcAge, formatDate, getDatePart, getFileAge, getOptionLabel } from 'utils'
import TalentBookInfo from 'components/TalentBookInfo'
import NoPhotoImg from 'asset/no-photo.png'
import Pagination from "components/Pagination";

const SortIcons = {
  '0': null,
  '1': <FaSortAlphaDown />,
  '-1': <FaSortAlphaUp />
}

let loadTimeoutHandle = null

const ProducerSearchTalents = () => {
  const { currentSearch, getOneSearch, createSearch, updateSearch } = useSearch()
  const { id: searchId } = useParams()
  const [showBookInfo, setShowBookInfo] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const enumData = useSelector(state => state.talent.enumData)
  const user = useSelector(state => state.user.currentUser)
  const [talents, setTalents] = useState([])
  const [loadingTalent, setLoadingTalent] = useState(false)
  const [savingSearch, setSavingSearch] = useState(false)
  const {setBreadcrumbs, addNotification} = useUIContext()
  const [currentTalent, setCurrentTalent] = useState(null)
  const [selectedTalents, setSelectedTalents] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchIdLoading, setSearchIdLoading] = useState(true)
  const location = useLocation()
  const homePath = location.pathname.split('/')[1]
  const {
    formState: { errors },
    control,
    handleSubmit,
    register,
    reset
  } = useForm()
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 50,
    totalPages: 1,
  });

  const [sort, setSort] = useState({
    first_name: null,
    last_name: null,
  })

  const loadTalents = async (search, selected_talents) => {
    setLoadingTalent(true)
    try {
      const talentsRes = await listTalentsBySearch({
        ...search,
        selected_talents: !!(searchId && searchId === 'new') ? '' : selected_talents,
        page_num: pagination.page,
        per_page: pagination.perPage,
        accepted_only: true,
        sort
      })
      setTalents(talentsRes.data.talents)
      setTotalCount(talentsRes.data.total_count)
      setPagination({
        ...pagination,
        totalPages: Math.ceil(talentsRes.data.total_count / talentsRes.data.per_page),
      });
      if (selectedTalents?.length === 0) {
        if (!!(searchId && searchId === 'new')) {
          const allTalentsRes = await listTalentsBySearch({
            ...currentSearch.search,
            accepted_only: true,
          })
          const all_selected_talents = allTalentsRes.data.talents.map(t => t._id)
          setSelectedTalents(all_selected_talents)
          dispatch(setCurrentSearch({
            ...currentSearch,
            ...!!(searchId && searchId === 'new') && {selected_talents: all_selected_talents}
          }))
        } else {
          setSelectedTalents(selected_talents)
        }
      }
    } catch (err) {
      addNotification(`Loading Talents Failed: ${err}`)
    }
    setLoadingTalent(false)
  }

  useEffect(() => {
    loadTalents(currentSearch.search, currentSearch.selected_talents);
  }, [pagination.page, pagination.perPage]);

  const handleSaveSearch = async (value) => {
    setSavingSearch(true)
    try {
      if (searchId && searchId !== 'new') {
        await updateSearch(searchId, {
          ...currentSearch,
          selected_talents: selectedTalents,
          ...value
        })
        addNotification({
          status: 'success',
          content: 'Saved'
        })
      } else {
        const searchRes = await createSearch({
          ...currentSearch,
          selected_talents: selectedTalents,
          ...value
        })
        if (searchRes._id) {
          navigate(`/${homePath}/dashboard/search/${searchRes._id}`)
        }
      }
    } catch (err) {
      addNotification({
        status: 'error',
        content: `Failed to Save: ${err}`
      })
    }
    setSavingSearch(false)
  }

  useEffect(() => {
    dispatch(setCurrentSearch({
      ...currentSearch,
      ...!!(searchId && searchId === 'new') && {selected_talents: selectedTalents},
      ...!!(searchId && searchId === 'new') && {_id: null}
    }))
  }, [talents, searchId])

  useEffect(() => {
    const loadSearch = async () => {
      let pathLabel = 'Create New Search'
      if (searchId !== 'new') {
        setSearchIdLoading(true)
        const searchRes = await getOneSearch(searchId)
        setCurrentSearch(searchRes)
        reset({
          owner: searchRes.owner,
          name: searchRes.name,
          description: searchRes.description
        })
        pathLabel = searchRes.name
      }
      setBreadcrumbs([])
      setSearchIdLoading(false)
    }
    loadSearch()
    return () => {
      dispatch(clearCurrentSearch())
      setSelectedTalents([])
      setTotalCount(0)
    }
  }, [searchId])

  useEffect(() => {
    if (searchIdLoading) {
      return
    }
    if (loadTimeoutHandle) {
      clearTimeout(loadTimeoutHandle)
    }
    loadTimeoutHandle = setTimeout(() => {
      loadTalents(currentSearch.search, currentSearch.selected_talents)
    }, 300)
  }, [currentSearch.search, searchIdLoading])

  const toggleTalentSelect = (talent) => {
    const selected = selectedTalents.includes(talent._id)
    const updatedSelects = [...selectedTalents]
    const idx = selectedTalents.findIndex(i => i === talent._id)
    if (selected) {
      updatedSelects.splice(idx, 1)
    } else {
      updatedSelects.push(talent._id)
    }
    setSelectedTalents(updatedSelects)
  }

  const sortedTalents = useMemo(() => {
    let result = talents

    if(
      !!currentSearch && currentSearch.selected_talents && currentSearch.selected_talents.length > 0 
      && !!searchId && searchId !== 'new'
    ) {
      result = result.filter(talent => currentSearch.selected_talents.includes(talent._id));
    }

    return result
  }, [talents, selectedTalents])

  const filteredBookouts = (talent) => {
    const filteredBookouts = talent?.bookouts?.filter(bookout => !!bookout.start_date);
    return filteredBookouts
  }

  return (
    <Box width={containerWidth} mx='auto' mb={12}>
      <Box mb={5}>
        <Card
          bodyProps={{m: 0}} 
          title='Save Search'
        >
          <Box
            overflow='visible'
            px={0} py={4}
          >
            <form onSubmit={handleSubmit(handleSaveSearch)}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Input {...register('name', { required: 'This field is required' })}/>
                <FormErrorMessage>{(errors.name && errors.name.message)}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>Message</FormLabel>
                <Input {...register('description')}/>
              </FormControl>
              <Box mt={3}>
                <Button isLoading={savingSearch} disabled={savingSearch} type='submit'>Save</Button>
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
      <Box display='flex' mb={3} ml={3} alignItems='center'>
        <Text mr={3}>{totalCount} Total</Text>
        <Text mr={3}>{selectedTalents.length} Selected</Text>
        <Button onClick={() => {
          setSelectedTalents(totalCount === selectedTalents?.length ? [] : currentSearch.selected_talents)
        }}>
          {totalCount === selectedTalents?.length ? 'Unselect All' : 'Select All'}
        </Button>
        <Button ml={2} onClick={() => {
          switch (sort.first_name) {
            case null:
              setSort({ ...sort, first_name: 1 })
              break
            case 1:
              setSort({ ...sort, first_name: -1 })
              break
            case -1:
              setSort({ ...sort, first_name: null })
              break
            default: 
              setSort({ ...sort, first_name: null })
              break
          }
        }} rightIcon={SortIcons[`${sort.first_name}`]}>
          Sort By Name
        </Button>
        <Button ml={2} onClick={() => {
          switch (sort.last_name) {
            case null:
              setSort({ ...sort, last_name: 1 })
              break
            case 1:
              setSort({ ...sort, last_name: -1 })
              break
            case -1:
              setSort({ ...sort, last_name: null })
              break
            default: 
              setSort({ ...sort, last_name: null })
              break
          }
        }} rightIcon={SortIcons[`${sort.last_name}`]}>
          Sort By Last Name
        </Button>
        {loadingTalent && (<Spinner />)}
      </Box>
      <Box display='flex' mt={3} alignItems='center' justifyContent='flex-end'>
        <Pagination pagination={pagination} setPagination={setPagination} perPageCountIncludes={['50', '100']} />
      </Box>
      <Box
        display='flex'
        flexWrap='wrap'
        justifyContent='space-around'
        gap={3}
        mt={5}
      >
        {sortedTalents.map(talent => {
          const selected = selectedTalents.includes(talent._id)
          return (
            <Box
              w={44}
              key={talent._id}
              display='flex'
              flexDirection='column'
              p={3}
              borderRadius={5}
              alignItems='center'
              bgColor={selected ? colors.doubleDark : colors.light}
            >
              {talent.photos[0] ? (
                <Box mt='auto' w='100%' mb={3}>
                  <Image 
                    borderRadius={10}
                    overflow='hidden'
                    mx='auto'
                    src={`${API_URL}/s3/${encodeURIComponent('150x150/' + talent.photos[0])}`}
                    h={40}
                    flex={1}
                    objectFit='contain'
                    cursor='default'
                  />
                </Box>
              ): <Box flex={1} cursor='default'>
                <Image
                    borderRadius={10}
                    src={NoPhotoImg}
                />
                <Text fontSize='xs' textAlign='center' color={selected ? colors.primaryText : colors.primary}>No photo available.</Text>
              </Box>}
              <Text
                mt='auto'
                color={selected ? colors.primaryText : colors.primary}
                textAlign='center'
                cursor='pointer'
                onClick={() => window.open(`/talent/${talent._id}`)}
              >{talent.first_name} {talent.last_name}</Text>
              <Text
                color={selected ? colors.primaryText : colors.primary}
                my={2} whiteSpace='nowrap' textAlign='center'
              >
                  {calcAge(talent)}
              </Text>

              <Button onClick={() => { toggleTalentSelect(talent) }}>
                {selected ? 'Unselect': 'Select'}
              </Button>
            </Box>
          )
        })}
      </Box>
      <Modal isOpen={!!currentTalent} onClose={() => setCurrentTalent(null) }>
        <ModalOverlay />
        {currentTalent && (
          <ModalContent>
            <Card
              title={`${currentTalent.first_name} ${currentTalent.last_name}`}
              bodyProps={{width: '100%',  px: 7}}
            >
              <Box mb={3} display='flex' justifyContent='space-between' alignItems='center'>
                <Checkbox defaultChecked={selectedTalents.includes(currentTalent._id)} onChange={() => {
                  toggleTalentSelect(currentTalent)
                }}>
                  Talent is Selected
                </Checkbox>
                <Button
                  as={Link}
                  leftIcon={<FaEnvelope />}
                  href={`mailto:${currentTalent.email}`}
                  target='_blank'
                >
                  Email
                </Button>
              </Box>
              <Swiper navigation={true} modules={[Navigation]}>
                {currentTalent.photos.map(photo => {
                  return (
                    <SwiperSlide key={photo}>
                      <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
                        <Image
                          width={120}
                          borderRadius={5}
                          overflow='hidden'
                          src={`${API_URL}/s3/${encodeURIComponent(photo)}`}
                        />
                        <Text mt={3} whiteSpace='nowrap'>{getFileAge(photo)}</Text>
                      </Box>
                    </SwiperSlide>
                  )
                })}
              </Swiper>

              <List>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Date of Birth:</Text>
                  <Text>{getDatePart(currentTalent.birth_date)}</Text>
                </ListItem>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Eye Color:</Text>
                  <Text>{currentTalent.eye_color}</Text>
                </ListItem>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Hair Color:</Text>
                  <Text>{currentTalent.hair_color}</Text>
                </ListItem>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Height:</Text>
                  <Text>{getOptionLabel(currentTalent.height, enumData.height)}</Text>
                </ListItem>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Weight</Text>
                  <Text>{getOptionLabel(currentTalent.weight, enumData.weight)}</Text>
                </ListItem>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Clothing Size:</Text>
                  <Text>{getOptionLabel(currentTalent.cloth_size, enumData.cloth_size)}</Text>
                </ListItem>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Shoe Size:</Text>
                  <Text>{getOptionLabel(currentTalent.shoe_size, enumData.shoe_size)}</Text>
                </ListItem>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Ethnicities</Text>
                  <Text>{getOptionLabel(currentTalent.ethnicity_look, enumData.ethnicity, true)}</Text>
                </ListItem>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Ethnicity Actual</Text>
                  <Text>{getOptionLabel(currentTalent.ethnicity_actual, enumData.ethnicity, true)}</Text>
                </ListItem>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Union Status</Text>
                  <Text>{currentTalent.union_status}</Text>
                </ListItem>
                <ListItem display='flex' justifyContent='space-between'>
                  <Text>Bookouts:</Text>
                  <Box>
                    {filteredBookouts(currentTalent)?.map((bookout, idx) => {
                      return (
                        <Text key={idx}>
                          {formatDate(bookout.start_date)}~{formatDate(bookout.end_date)} : {bookout.note}
                        </Text>
                      )
                    })}
                    {filteredBookouts(currentTalent)?.length === 0 && (
                      'No bookouts'
                    )}
                  </Box>
                </ListItem>

              </List>
              <Box display='flex' mt={3} flexWrap='wrap' rowGap={2}>
                <Button leftIcon={<FaEye />} onClick={() => {
                  window.open(`/talent/${currentTalent._id}`)
                }}>
                  View Full Profile
                </Button>
                {currentTalent.resume && (
                  <Button leftIcon={<FaFilePdf />} ml='auto' onClick={() => {
                    window.open(`${API_URL}/s3/${encodeURIComponent(currentTalent.resume)}`, '_blank')
                  }}>
                    Download Resume
                  </Button>
                )}
                <Button leftIcon={<FaCopy />} w='100%' onClick={() => {
                  setShowBookInfo(true)
                }}>
                  Generate Booking Information
                </Button>
              </Box>
            </Card>
          </ModalContent>
        )}
      </Modal>

      <Modal isOpen={currentTalent && showBookInfo} onClose={() => {
        setShowBookInfo(false)
      }} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent>
          {currentTalent && (
            <TalentBookInfo
              talent={currentTalent}
              close={() => {
                setShowBookInfo(false)
              }}
            />
          )}
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default ProducerSearchTalents
